<template>
  <!-- 发运信息详情 -->
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <div>{{ $t('title.BasicInformation') }}</div>
    </el-row>
    <el-form label-width="102px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="货主:">{{ DetailsData.cargoOwnerCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库:">{{ DetailsData.logicWarehouseCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库类型:">{{ getWarehouseTypeLabel(DetailsData.warehouseType ) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SKU:">{{ DetailsData.sku }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style:">{{ DetailsData.style }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Color:">{{ DetailsData.color }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- <el-form-item label="Size:">{{ DetailsData.size }}</el-form-item> -->
          </el-col>
          <el-col :span="8">
            <el-form-item label="库存期间:">{{ DetailsData.inventoryPeriod }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="期初数量:">{{ DetailsData.beginningQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="期末数量:">{{ DetailsData.endingQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="DetailsData.quantityName +':'">{{ DetailsData.quantity }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <el-row class="grid-content bg-purple-dark">
      <div>单据信息</div>
    </el-row>
    <el-table ref="multipleTable" class="mb-3" :data="DetailsDataList" :header-cell-style="{background:'#fafafa'}" max-height="500px">
      <el-table-column
        type="index"
        :label="$t('page.No')"
        align="center"
      />
      <el-table-column
        prop="taskCode"
        label="作业单号"
        align="center"
      />
      <el-table-column
        prop="businessCode"
        label="业务单号"
        align="center"
      />
      <el-table-column
        prop="businessType"
        label="业务类型"
        align="center"
      >
        <template slot-scope="scope">{{ getBusinessTypeLabel(scope.row.businessType) }}</template>
      </el-table-column>
      <el-table-column
        prop="quantity"
        label="数量"
        align="center"
      />
      <el-table-column
        prop="addTime"
        label="添加时间"
        align="center"
      />
    </el-table>
    <Paging :pager="pager" @update="update" />
    <el-row class="grid-content bg-blue mt-5" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>

</template>

<script>
import { pageDetails } from '@/api/inventory-result'
import { Mixin } from '@/mixin/mixin.js'
import Paging from '@/components/Paging'
export default {
  components: {
    Paging
  },
  mixins: [Mixin],
  data() {
    return {
      DetailsData: {},
      DetailsDataList: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      params: {}
    }
  },
  computed: {
    queryParams() {
      return Object.assign({}, this.pager, this.params)
    }
  },
  mounted() {
    const { color, size, sku, style, warehouseType, cargoOwnerCode, beginningQuantity, endingQuantity, logicWarehouseCode, inventoryPeriod, sourceWarehouseCode } = JSON.parse(this.$route.query.row)
    this.DetailsData = { quantity: this.$route.query.quantity, quantityName: this.$route.query.quantityName, color, size, sku, style, warehouseType, cargoOwnerCode, beginningQuantity, endingQuantity, logicWarehouseCode, inventoryPeriod }
    this.params = { businessType: this.$route.query.businessType, cargoOwnerCode, inventoryPeriod, sku, sourceWarehouseCode }
    this._pageDetails(this.params)
  },
  methods: {
    cancle() {
      this.$router.go(-1)
    },
    // 获取详情数据
    async _pageDetails() {
      const { datas: { pager, records }} = await pageDetails(this.queryParams)
      this.pager = pager
      this.DetailsDataList = records
    },
    update(val) {
      console.log(val)
      this.pager = val
      this._pageDetails()
    }

  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
</style>
